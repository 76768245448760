@import './colors.scss';

.svg-animation svg {
  pointer-events: none;
  filter: drop-shadow(0px 0px 2px #000 );
  path {
    fill: $svgAnimation;
    stroke: $svgAnimation;
  }
}
