.react-orientation {
  display: none;
}

.overlay {
	display:none;
	position:absolute;
  top:0;
  left:0;
	background-color:rgba(255,255,255,.7);
	width:100%;
	height:100%;
}

.iconContainer {
	width:200px;
	height:200px;
	position:absolute;
	left:50%;
	top:50%;
	margin-left:-100px;
	margin-top:-100px;
	.rotate-text {
		display:inline-block;
		width:100%;
		text-align:center;
		margin:0;
		font-size:1.5rem;
		opacity:0;
	}
}

.phone {
	position:relative;
	left:50%;
	margin-left:-50px;
	width:0;
	height:0;
	border:0 solid #000;
	background:white;
	border-radius:10px;
	box-sizing:border-box;
	.fa-redo-alt {
    margin-top: 35px;
		text-align:center;
		width:100%;
		line-height:120px;
		font-size:50px;
		color:#454545;
		opacity:0;
	}
}

@media all and (orientation: landscape) {
  .react-orientation--landscape {
    display: inherit;
  }
}

@media all and (orientation: portrait) {
  .react-orientation--portrait {
    display: inherit;
  }
}

@media only screen {
	.overlay {
    display: block;

    .phone {
      animation:
        sizeIncrease .5s forwards ease, 
        borderIncrease .5s .5s forwards ease, 
        rotateRight .7s 1s forwards ease;

      .fa-redo-alt {
        animation: fadeIn .5s .8s forwards ease;
      }
    }

    .iconContainer {
      .rotate-text {
        animation: fadeIn .5s 1.3s forwards ease;
      }
    }
  }
}

/* Animate width + height */
@keyframes sizeIncrease {
  0%   { width: 0; height:10px; }
	50%  { width: 100px; height: 10px; }
  100% { width: 100px; height:160px; }
}
/* Add borders */
@keyframes borderIncrease { 
	100% { border-width:20px 10px; } 
}

/* fade-in  */
@keyframes fadeIn { 
	100% { color:#000; opacity:1; } 
}

/* Rotate device */
@keyframes rotateRight { 
	100% { transform: rotate(90deg); } 
}
