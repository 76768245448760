.flash {
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  pointer-events: none;

  &.is-visible {
    opacity: 1;
    transition: opacity 0ms linear;
  }

  &.is-hidden {
    opacity: 0;
    transition: opacity 500ms ease-out;
  }
}