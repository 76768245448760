.modal-async-error {
  .modal-content {
    font-size: 1.5rem;
    color: white;
    background-color: transparent;
    border: none;

    .modal-title {
      font-size: 2rem;
    }

    .modal-header, .modal-body, .modal-footer {
      border: none;
    }
  }
}

.modal-backdrop-async-error.show {
  opacity: 0.75 !important;
}
