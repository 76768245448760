@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-ExtraLight.eot');
  src: url('../assets/fonts/Raleway/Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-ExtraLight.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-ExtraLight.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-ExtraLight.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-ExtraLight.svg#Raleway-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Bold.eot');
  src: url('../assets/fonts/Raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-Bold.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-Bold.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-Bold.svg#Raleway-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Black.eot');
  src: url('../assets/fonts/Raleway/Raleway-Black.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-Black.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-Black.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-Black.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-Black.svg#Raleway-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-ExtraBoldItalic.eot');
  src: url('../assets/fonts/Raleway/Raleway-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-ExtraBoldItalic.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-ExtraBoldItalic.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-ExtraBoldItalic.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-ExtraBoldItalic.svg#Raleway-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-BoldItalic.eot');
  src: url('../assets/fonts/Raleway/Raleway-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-BoldItalic.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-BoldItalic.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-BoldItalic.svg#Raleway-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-BlackItalic.eot');
  src: url('../assets/fonts/Raleway/Raleway-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-BlackItalic.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-BlackItalic.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-BlackItalic.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-BlackItalic.svg#Raleway-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-ExtraBold.eot');
  src: url('../assets/fonts/Raleway/Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-ExtraBold.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-ExtraBold.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-ExtraBold.svg#Raleway-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Italic.eot');
  src: url('../assets/fonts/Raleway/Raleway-Italic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-Italic.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-Italic.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-Italic.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-Italic.svg#Raleway-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-ThinItalic.eot');
  src: url('../assets/fonts/Raleway/Raleway-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-ThinItalic.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-ThinItalic.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-ThinItalic.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-ThinItalic.svg#Raleway-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-MediumItalic.eot');
  src: url('../assets/fonts/Raleway/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-MediumItalic.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-MediumItalic.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-MediumItalic.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-MediumItalic.svg#Raleway-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-LightItalic.eot');
  src: url('../assets/fonts/Raleway/Raleway-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-LightItalic.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-LightItalic.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-LightItalic.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-LightItalic.svg#Raleway-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Thin.eot');
  src: url('../assets/fonts/Raleway/Raleway-Thin.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-Thin.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-Thin.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-Thin.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-Thin.svg#Raleway-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Light.eot');
  src: url('../assets/fonts/Raleway/Raleway-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-Light.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-Light.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-Light.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-Light.svg#Raleway-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Regular.eot');
  src: url('../assets/fonts/Raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-Regular.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-Regular.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-Regular.svg#Raleway-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-ExtraLightItalic.eot');
  src: url('../assets/fonts/Raleway/Raleway-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-ExtraLightItalic.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-ExtraLightItalic.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-ExtraLightItalic.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-ExtraLightItalic.svg#Raleway-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-SemiBoldItalic.eot');
  src: url('../assets/fonts/Raleway/Raleway-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-SemiBoldItalic.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-SemiBoldItalic.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-SemiBoldItalic.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-SemiBoldItalic.svg#Raleway-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-Medium.eot');
  src: url('../assets/fonts/Raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-Medium.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-Medium.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-Medium.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-Medium.svg#Raleway-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/Raleway/Raleway-SemiBold.eot');
  src: url('../assets/fonts/Raleway/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Raleway/Raleway-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/Raleway/Raleway-SemiBold.woff') format('woff'),
      url('../assets/fonts/Raleway/Raleway-SemiBold.ttf') format('truetype'),
      url('../assets/fonts/Raleway/Raleway-SemiBold.svg#Raleway-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('../assets/fonts/Times New Roman/TimesNewRoman-BoldItalic.eot');
  src: url('../assets/fonts/Times New Roman/TimesNewRoman-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-BoldItalic.woff') format('woff'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-BoldItalic.ttf') format('truetype'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-BoldItalic.svg#TimesNewRoman-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('../assets/fonts/Times New Roman/TimesNewRoman-Italic.eot');
  src: url('../assets/fonts/Times New Roman/TimesNewRoman-Italic.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Italic.woff2') format('woff2'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Italic.woff') format('woff'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Italic.ttf') format('truetype'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Italic.svg#TimesNewRoman-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('../assets/fonts/Times New Roman/TimesNewRoman-Regular.eot');
  src: url('../assets/fonts/Times New Roman/TimesNewRoman-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Regular.woff2') format('woff2'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Regular.woff') format('woff'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Regular.ttf') format('truetype'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Regular.svg#TimesNewRoman-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('../assets/fonts/Times New Roman/TimesNewRoman-Bold.eot');
  src: url('../assets/fonts/Times New Roman/TimesNewRoman-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Bold.woff2') format('woff2'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Bold.woff') format('woff'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Bold.ttf') format('truetype'),
      url('../assets/fonts/Times New Roman/TimesNewRoman-Bold.svg#TimesNewRoman-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


html {
  font-size: 1rem;
}

@include media-breakpoint-up(xs) {
  html {
    font-size: 0.75rem;
  }
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 0.85rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }
}
