$primary: #000000;
$secondary: #ffcc00;
$background: #ffffff;
$navBackground: transparent;
$countdown: $primary;
$countdownLastSecond: $secondary;
$title: $primary;

$svgAnimation: white;

:export {
  primary: $primary;
  secondary: $secondary;
}
