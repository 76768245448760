@import "./colors.scss";
@import "./animations.scss";


$keyboard-background: rgba(218, 218, 218, 0.75);
$key-background: #F5F5F5;
$key-color: black;
$key-active-background: $primary;
$key-active-color: white;
$specialkey-background: transparent;
$specialkey-color: $secondary;
$specialkey-active-background: $key-background;
$specialkey-active-color: $primary;

.keyboard-container {
  position: fixed;
  bottom: 0;
  padding: 5px;
  left: 50%;
  transform: translateX(-50%);
  background: $keyboard-background;
  width: 100%;
  backdrop-filter: blur(20px);

  &.keyboard-blink {
    animation: keyboard-blink 1s ease-in-out infinite;
  }
}

.numeric-keyboard {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 15%;
}

.keyboard {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.keyboard-row {
  display: flex;
}

.keyboard-keysSet {
  display: flex;
  flex-grow: 1;
}

.keyboard-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-basis: 100px;
  font-size: 25px;
  height: 75px;
  margin: 5px;
  border-radius: 8px;
  color: $key-color;
  background-color: $key-background;
  border: 1px solid darken($key-background, 10%);
  font-weight: 400;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:active, &.clicked {
    color: $key-active-color;
    background-color: $key-active-background;
    border: 1px solid darken($key-active-background, 10%);
  }
}

.keyboard-backspaceButton,
.keyboard-shiftButton,
.keyboard-symbolButton {
  background-color: $specialkey-background;
  border: 1px solid darken($specialkey-color, 10%);
  color: $specialkey-color;
  &:active, &.clicked {
    color: $specialkey-active-color;
    background-color: $specialkey-active-background;
    border: 1px solid darken($specialkey-active-color, 10%);
  }
}

.keyboard-numberButton {
  flex-grow: 1;
}

.keyboard-halfButton {
  flex-basis: 56px;
}

.keyboard-space,
.keyboard-spaceButton{
  flex-grow: 1;
}

.keyboard-utilButton {
  flex-grow: 2;
  max-width: 55px;
}

.keyboard-additionalButton {
  flex-basis: 128px;
}

.keyboard-submit-button {
  flex-basis: 144px;
  background-color: #63b324;
  border-color: #63b324;
  color: #ffffff;
}
