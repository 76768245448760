@keyframes button-blink {
  50% {
    filter: brightness(110%);
    transform: scale(1.1);
  }
}

@keyframes keyboard-blink {
  50% {
    filter: brightness(105%) contrast(115%)
  }
}
