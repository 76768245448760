.liveview-container {
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;

  video {
    position: absolute;
    object-fit: cover;
    width:100%;
    height:100%;
    z-index: 0;
  }

  video::-webkit-media-controls {
    display:none !important;
  }
}
