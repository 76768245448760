.step-transition-enter {
  transform: translate(100vw);
  z-index: 10;

  &.step-transition-enter-active {
    transform: translate(0);
    transition: transform 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

.step-transition-exit {
  opacity: 1;
  z-index: 1;
  transform: translate(0);

  &.step-transition-exit-active {
    opacity: 0.01;
    transform: translate(-100vw);
    transition:
      opacity 150ms ease-out,
      transform 400ms ease-out;
  }
}
