@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "./colors.scss";

.react-select__placeholder {
  color: $input-placeholder-color !important;
  font-size: 1rem;
}

.react-select__control {
  border-color: $input-border-color !important;
  border-radius: 0.25rem;
}

.react-select__control--is-focused {
  border-color: lighten($primary, 25%) !important;
  box-shadow: 0 0 0 $input-btn-focus-width rgba($primary, 0.25) !important;
}
