@import "./colors.scss";

.CircularProgressbar {
  width: 100%;

  .CircularProgressbar-path {
    stroke: $countdown;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.25s ease-out 0s;
  }

  .CircularProgressbar-trail {
    stroke: transparent;
  }

  .CircularProgressbar-text {
    fill: $countdown;
    font-size: 20px;
    font-weight: 700;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  &.soon {
    .CircularProgressbar-path {
      stroke: $countdownLastSecond;
      transition: inherit, stroke 0.1s linear;
    }

    .CircularProgressbar-text {
      fill: $countdownLastSecond;
      transition: fill 0.1s linear;
    }
  }
}
